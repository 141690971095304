import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from '@ekipa/common-ui';
import { HeaderModule } from '../header/header.module';
import { AppLayoutComponent } from './app-layout.component';



@NgModule({
  declarations: [AppLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    FooterModule,
    RouterModule
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
