import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { API_URL, AuthService } from '@ekipa/common-ui';
import { take } from 'rxjs';

@Component({
  selector: 'ekipa-support-button',
  templateUrl: './support-button.component.html',
  imports: [CommonModule, FormsModule],
  standalone: true,
  styles: [`
  :host {
    position: fixed;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    max-width: 830px;
    max-height: 100vh;
    z-index: 999;
    border-radius: 4px;
  }
  `]
})

export class SupportButtonComponent implements OnInit {
  showSupportInput = false;
  isSuccess = false;
  supportType = '';
  email = '';
  message = '';

  httpClient = inject(HttpClient);
  apiURL = inject(API_URL)
  authService = inject(AuthService);

  ngOnInit() {
    this.authService.authState$.pipe(take(1)).subscribe((user) => {
      if (user) {
        this.email = user.email;
      }
    })
  }

  onClick() {
    this.httpClient.post(`${this.apiURL}support`, {
      type: this.supportType,
      email: this.email,
      message: this.message
    }).subscribe((res) => {
      this.isSuccess = true;
    })
  }

}
