<div *ngIf="!showSupportInput" (click)="showSupportInput = true"
  class="flex gap-4 items-center justify-center rounded-lg shadow-lg px-4 m-3 p-3 bg-white text-gray-600 hover:text-gray-800 cursor-pointer">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
    class="w-10 h-10 pr-3 border-r-2 border-gray-100">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
  </svg>
  <h2 class="text-xl title-font font-medium">
    Support
  </h2>
</div>

<div class="flex flex-col  rounded-md shadow-lg p-8 m-4 bg-white md:w-96" *ngIf="showSupportInput">
  <div class="flex justify-end">
    <svg (click)="showSupportInput = !showSupportInput; isSuccess = false" xmlns="http://www.w3.org/2000/svg"
      fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
  <ng-container *ngIf="!isSuccess">
    <h2 class="text-xl title-font font-medium text-gray-900 mb-8">Support </h2>

    <div class="flex flex-col gap-2">
      <label class="">
        <input type="radio" [(ngModel)]="supportType" value="bug"
          class="rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500">
        <span class="ml-2">I am experiencing a bug</span>
      </label>
      <label class="">
        <input type="radio" [(ngModel)]="supportType" value="feedback"
          class="rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500">
        <span class="ml-2">I have feedback</span>
      </label>
      <label class="">
        <input type="radio" [(ngModel)]="supportType" value="support"
          class="rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500">
        <span class="ml-2">I need support or general advice on challenges</span>
      </label>
    </div>
    <br />
    <input type="text" [(ngModel)]="email" placeholder="Contact email" class="mb-4" />
    <textarea [(ngModel)]="message" placeholder="Your message" class=""></textarea>
    <button class="btn btn-primary mt-8" [disabled]="!supportType || !email || !message"
      (click)="onClick()">Send</button>
  </ng-container>
  <ng-container *ngIf="isSuccess">
    <div class=" p-6 pb-2 md:mx-auto z-10">
      <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
        <path fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
        </path>
      </svg>
      <div class="text-center">
        <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">On it! </h3>
        <p class="text-gray-600 my-2">We have received your support request and will reach out to you via email during
          our next business hour.</p>
      </div>
    </div>
  </ng-container>
</div>
