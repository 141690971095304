import { isPlatformServer } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  API_URL,
  BASE_URL,
  CORE_GRAPHQL_URL,
  CookieModule,
  DeveloperSettingsModule,
  GraphQLModule,
  IS_PRODUCTION,
  PRISMIC_GRAPHQL_URL,
  SwUpdatesModule,
  SwUpdatesService,
  TranslocoRootModule
} from '@ekipa/common-ui';
import { environment } from '@ekipa/shared';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { CookieModule as NGXCookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsModule } from './settings/settings.module';
import { AppLayoutModule } from './shared/app-layout/app-layout.module';
import { SupportButtonComponent } from './shared/support-button/support-button.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    TranslocoRootModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    HttpClientModule,
    AppLayoutModule,
    GraphQLModule,
    MatSnackBarModule,
    SettingsModule,
    // InvitedViewModule,
    NGXCookieModule.forRoot(),
    CookieModule,
    SwUpdatesModule,
    DeveloperSettingsModule,
    ServiceWorkerModule.register('safety-worker.js', {
      enabled: environment.production,
    }),
    SupportButtonComponent
  ],
  providers: [
    provideClientHydration(),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      animation: 'scale',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    { provide: API_URL, useValue: environment.apiURL },
    { provide: IS_PRODUCTION, useValue: environment.production },
    { provide: PRISMIC_GRAPHQL_URL, useValue: environment.prismicGraphQL },
    { provide: CORE_GRAPHQL_URL, useValue: environment.coreGraphQL },
    { provide: BASE_URL, useValue: environment.baseUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    public appRef: ApplicationRef,
    private swUpdateService: SwUpdatesService,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    const domain = (isPlatformServer(platformId)) ? environment.baseUrl : '';
    this.matIconRegistry.addSvgIconSet(

      /**
       * Register Material Icons
       * SVG file structure taken from https://raw.githubusercontent.com/Templarian/MaterialDesign-Angular-Material/master/mdi.svg
       * SVGs taken from https://fonts.google.com/icons?icon.platform=web
       */
      //
      this.domSanitzer.bypassSecurityTrustResourceUrl(`${domain}/assets/icons/material/mdi.svg`)
    );
  }


}



