import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChallengesService, CookieService, IS_PRODUCTION, SeoService } from '@ekipa/common-ui';
import { mergeMap } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'ekipa-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  showDevSettings = false;
  challengesService = inject(ChallengesService);
  document = inject(DOCUMENT);
  route = inject(ActivatedRoute);

  constructor(
    private cookieService: CookieService,
    @Inject(IS_PRODUCTION) private isProduction: string,
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
  ) {
    if (!isProduction && isPlatformBrowser(platformId)) {
      this.showDevSettings = true
    }
    this.cookieService.load()
  };

  ngOnInit() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route: ActivatedRoute) => {
        if (this.router.url.includes('deloitte-fast50-2023')) {
          this.challengesService.isFast50 = true;
        } else {
          this.challengesService.isFast50 = false;
        }
        if (this.router.url.includes('samsung-health')) {
          this.challengesService.hasConfig = true;
        } else {
          this.challengesService.hasConfig = false;
        }
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      const seoData = data['seo'];
      this.seoService.updateMetaTags(seoData?.metaTags);
      this.seoService.updateTitle(seoData?.title);
    });

  }
}
