import { NgModule } from '@angular/core';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login-container.component').then(m => m.LoginContainerComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./auth/register/register-container.component').then(m => m.RegisterContainerComponent),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./auth/reset-password/reset-password-container.component').then(m => m.ResetPasswordContainerComponent)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    component: AppLayoutComponent,
    ...canActivate(() => redirectUnauthorizedTo(['/'])),
    data: {
      seo: {
        title: 'ekipa - Dashboard',
      }
    }
  },
  {
    path: 'challenges',
    loadChildren: () => import('./challenges/challenges.module').then(m => m.ChallengesModule),
    component: AppLayoutComponent,
    data: {
      seo: {
        title: 'ekipa - All Challenges',
      }
    }
  },
  {
    path: 'participate',
    loadChildren: () => import('./participation/participation.module').then(m => m.ParticipationModule),
    component: AppLayoutComponent
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    component: AppLayoutComponent
  },
  {
    path: 'settings',
    ...canActivate(() => redirectUnauthorizedTo(['/'])),
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    ...canActivate(() => redirectUnauthorizedTo(['/auth/login'])),
    component: AppLayoutComponent
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    component: AppLayoutComponent,
    data: {
      seo: {
        title: 'ekipa - Frequently asked questions',
      }
    }
  },
  {
    path: 'program',
    redirectTo: 'programs',
  },
  {
    path: 'challenge',
    redirectTo: 'challenges',
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email-routing.module').then(m => m.VerifyEmailRoutingModule)
  },
  {
    path: 'vote',
    loadChildren: () => import('./vote/vote.module').then(m => m.VoteModule),
  },
  {
    path: 'samsung',
    redirectTo: '/challenges/techideas-gennext/brief',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AppLayoutComponent,
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  },
  // {
  //   path: '',
  //   redirectTo: '/challenges',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
